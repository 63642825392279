import { AxiosError, isAxiosError } from 'axios'
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorResponse } from 'silta-ai-backend'
import { apiClient } from 'utils/clients'
import { route } from '../utils/routes'
import {
    UserSession,
    clearStoredSession,
    getStoredSession,
    storeSession,
    toUserSession,
} from '../utils/session'
import { getStoredTeam, storeCurrentTeam, clearStoredTeam } from '../utils/team'

interface AuthContextType {
    loginError: string | null
    signin: (formState: { email: string; password: string }) => Promise<void>
    logout: (withApiCall?: boolean) => void
    session: UserSession | null
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

function useAuth(): AuthContextType {
    const auth = useContext(AuthContext)
    if (!auth) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return auth
}

function useAuthProvider(): AuthContextType {
    const navigate = useNavigate()
    const location = useLocation()

    const storedSession = getStoredSession()

    const [loginError, setLoginError] = useState<string | null>(null)
    const [session, setSession] = useState<UserSession | null>(storedSession)

    const signin = async (formState: { email: string; password: string }) => {
        setLoginError(null)

        try {
            const session = await apiClient.login(
                formState.email,
                formState.password
            )
            const userSession = toUserSession(formState.email, session)
            setSession(userSession)
            storeSession(userSession)

            const teams = await apiClient.getTeams()
            let activeTeam = getStoredTeam(userSession.userId)

            // Check if activeTeam is part of user's teams
            const isActiveTeamValid = teams?.some(
                (team) => team.id === activeTeam?.id
            )

            if (!isActiveTeamValid) {
                // Clear the stored team if it's not valid
                clearStoredTeam(userSession.userId)

                // If user has teams, select the first one
                if (teams.length > 0) {
                    const firstTeam = teams[0]
                    if (firstTeam) {
                        activeTeam = firstTeam
                        storeCurrentTeam(userSession.userId, activeTeam)
                    }
                } else {
                    // If user has no teams, set activeTeam to null
                    activeTeam = null
                }
            }

            apiClient.setSelectedTeam(activeTeam?.id)
        } catch (err) {
            const errorText = isAxiosError(err)
                ? ((err as AxiosError).response?.data as ErrorResponse).error
                : (err as Error).message
            setLoginError(errorText)
        }
    }

    const logout = async (withApiCall = false) => {
        if (withApiCall) {
            await apiClient.logout()
        }

        clearStoredSession()
        setSession(null)

        // Pages that require authentication redirect to a public page.

        const publicRoutes = [/\/login(\/.*)?/]
        if (!publicRoutes.some((route) => route.test(location.pathname))) {
            navigate(route('home'))
        }
    }

    return {
        loginError,
        signin,
        logout,
        session,
    }
}

function AuthProvider({ children }: { children: ReactNode }) {
    const auth = useAuthProvider()
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export { AuthProvider, useAuth }
