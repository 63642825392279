import React, { ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { route } from '../utils/routes'
import { Icon, IconName } from './Icon'
import { SidebarDropdownMenu } from './SidebarDropdownMenu'

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background-color: ${themeVariables.colors.backgroundSurface};
`

const SideBar = styled.div`
    min-width: 288px;
    padding: 20px;
`

const ContanentContainer = styled.div`
    flex: 1;
    box-shadow:
        -1px 0 3px rgb(0 0 0 / 0.025),
        -10px 109px 50px 0px #a3acba1a;
`

const Menu = styled.ul`
    list-style: none;
    padding: 0;
`

const MenuItemLink = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${themeVariables.colors.primary};
    padding: 8px 10px;
    &:hover {
        background-color: ${themeVariables.colors.backgroundContainer};
        color: ${themeVariables.colors.primary};
    }
    &.active {
        color: ${themeVariables.colors.brand};
        font-weight: ${themeVariables.typography.fontWeight.bold};
        background-color: ${themeVariables.colors.backgroundContainer};
    }
`

export const Layout = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const links = useMemo(
        () => [
            {
                url: route('home'),
                iconName: 'home',
                label: 'Home',
            },
            {
                url: route('assessments'),
                iconName: 'pieChart',
                label: 'Assessments',
            },
            {
                url: route('projects'),
                iconName: 'grid',
                label: 'Projects',
            },
            {
                url: route('dataRooms'),
                iconName: 'database',
                label: 'Precedent Databases',
            },
            {
                url: route('models'),
                iconName: 'server',
                label: 'Evaluation Criteria',
            },
            {
                url: route('reports'),
                iconName: 'barChart',
                label: 'Reports',
            },
            {
                url: route('reportTemplates'),
                iconName: 'package',
                label: 'Report Templates',
            },
        ],
        []
    )
    return (
        <MainContainer>
            <SideBar>
                <SidebarDropdownMenu />
                <Menu>
                    {links.map((menuLinkDefinition, index) => (
                        <li key={index}>
                            <MenuItemLink
                                to={menuLinkDefinition.url}
                                className={({ isActive }) =>
                                    isActive ? 'active' : ''
                                }
                            >
                                <Icon
                                    name={
                                        menuLinkDefinition.iconName as IconName
                                    }
                                />
                                {menuLinkDefinition.label}
                            </MenuItemLink>
                        </li>
                    ))}
                </Menu>
            </SideBar>
            <ContanentContainer>{children}</ContanentContainer>
        </MainContainer>
    )
}
