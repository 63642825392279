import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useModelsQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { route } from '../utils/routes'
import { useCreateModelModal } from '../components/modals/CreateModelModal'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'

export function ModelListPage() {
    const [search, setSearch] = useState<string>('')
    const modelsQuery = useModelsQuery({
        search,
    })
    const models = modelsQuery.data || []

    const navigate = useNavigate()

    const createModel = useCreateModelModal()

    return (
        <Layout>
            <ListTopBar onSearch={(query) => setSearch(query)} />
            <HorizontalLoadingIndicator loading={modelsQuery.isLoading} />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>Evaluation Criteria</h1>
                <Button $size="medium" onClick={() => createModel()}>
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">
                            Create Evaluation Criteria
                        </span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        render: (model) => (
                            <span className="emphasized">{model.name}</span>
                        ),
                        width: 'minmax(min-content, 80%)',
                    },
                    {
                        title: 'Questions',
                        key: 'questionCount',
                        render: (model) => (
                            <span className="emphasized">
                                {model._count.questions}
                            </span>
                        ),
                    },
                    {
                        title: 'Last updated',
                        key: 'updatedAt',
                        render: (model) => (
                            <DateRenderer value={model.updatedAt} />
                        ),
                    },
                ]}
                data={models}
                onRowClick={(model) => {
                    navigate(route('model', model.id))
                }}
                stickyHeader
            />
            {modelsQuery.isLoading && <TableDataLoading />}
            {!modelsQuery.isLoading && models.length === 0 && (
                <TableNoContent
                    icon="server"
                    title="Create first Evaluation Criteria"
                    description="Evaluation Criteria are sets of questions to evaluate Projects."
                    buttonText="Create Evaluation Criteria"
                    buttonOnClick={() => createModel()}
                />
            )}
        </Layout>
    )
}
