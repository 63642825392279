import React from 'react'
import { AssessmentWithRelations } from 'silta-ai-backend'
import styled from 'styled-components'
import cx from 'classnames'
import { useAnswerReferences } from 'utils/answers'
import { route } from 'utils/routes'
import { downloadDocument } from 'utils/download'
import { ReferenceMatch } from 'utils/references'
import { themeVariables } from '../themes/themeVariables'
import { Tooltip } from './Tooltip'
import { FileTypeIcon } from './FileTypeIcon'
import { Icon } from './Icon'

interface AnswerContentProps {
    answer: AssessmentWithRelations['answers'][0]
}

const ReferenceLink = styled.a`
    color: ${themeVariables.colors.secondary};
    text-decoration: none;
    font-size: 10px;
    padding: 1px 2px;
    border: 1px solid ${themeVariables.palettes.neutral400};
    border-radius: 2px;
    &:hover {
        color: ${themeVariables.colors.primary};
    }
`

export function AnswerContent({ answer }: AnswerContentProps) {
    const { paragraphs } = useAnswerReferences({
        answer,
        referenceRenderer: (match: ReferenceMatch) => {
            if (!match.reference.document && !match.reference.url) {
                return '[invalid reference]'
            }

            const doc = match.reference.document

            return (
                <sup>
                    <Tooltip
                        anchorDisplay="inline"
                        content={
                            <span className={cx('d-flex', 'g-5')}>
                                <FileTypeIcon
                                    fileName={match.reference.title}
                                />
                                <span>
                                    {match.reference.title}
                                    <Icon
                                        name="exportArrow"
                                        style={{
                                            display: 'inline',
                                            marginLeft: '5px',
                                        }}
                                    />
                                </span>
                            </span>
                        }
                    >
                        {doc && (
                            <ReferenceLink
                                href={route('document', doc.dataRoomId, doc.id)}
                                onClick={(e) => {
                                    e.preventDefault()
                                    downloadDocument(doc)
                                }}
                            >
                                Ref. {match.reference.index}
                                {match.sectionDescription
                                    ? `, ${match.sectionDescription}`
                                    : ''}
                            </ReferenceLink>
                        )}
                        {match.reference.url && (
                            <ReferenceLink
                                href={match.reference.url}
                                target="_blank"
                            >
                                Ref. {match.reference.index}
                                {match.sectionDescription
                                    ? `, ${match.sectionDescription}`
                                    : ''}
                            </ReferenceLink>
                        )}
                    </Tooltip>
                </sup>
            )
        },
    })

    return (
        <>
            {paragraphs.map((paragraph, index) => (
                <div className="m-b-10" key={`Answer-${answer.id}-${index}`}>
                    {paragraph}
                </div>
            ))}
        </>
    )
}
