import { AxiosError } from 'axios'
import {
    useIsMutating,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import {
    Answer,
    Assessment,
    CategoryAssignee,
    CreateAnswerRequest,
    CreateOutcomeRequest,
    CreateQuestionRequest,
    Model,
    ModelWithRelations,
    Outcome,
    OutcomeCriterion,
    Question,
    UpdateAnswerRequest,
    UpdateQuestionRequest,
} from 'silta-ai-backend'
import { useAssessmentDraft } from '../state/AssessmentDraft.state'
import { ProjectDraft } from '../types/projects'
import { apiClient } from './clients'
import {
    invalidateAnswerQuery,
    invalidateAssessmentQuery,
    invalidateDataRoomQuery,
    invalidateOutcomesQuery,
    invalidateProjectQuery,
    invalidateReportQuery,
    invalidateReportTemplateQuery,
    invalidateUserQuery,
    invalidateModelQuery,
    invalidateCategoryAssigneesQuery,
} from './queries'
import { route } from './routes'
import { invalidReportTemplateModal } from '../components/modals/InvalidReportTemplateModal'

/*
 * Model Outcomes
 */
export const useCreateOutcome = (transientId: string) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: ['useCreateOutcome', transientId],
        mutationFn: async ({ outcome }: { outcome: CreateOutcomeRequest }) =>
            apiClient.createOutcome(outcome),
        onSuccess: ({ modelId }) => {
            queryClient.invalidateQueries({
                queryKey: ['model', modelId],
            })

            invalidateOutcomesQuery(modelId)
        },
        onError: (error) => {
            console.error('Error creating outcome', error)
        },
    })
}

export const useIsOutcomeBeingCreated = (transientId: string): boolean =>
    useIsMutating({
        mutationKey: ['useCreateOutcome', transientId],
        exact: true,
    }) > 0

export const useUpdateOutcome = (outcomeId: string) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: ['useUpdateOutcome', outcomeId],
        mutationFn: async (
            payload: Partial<Omit<CreateOutcomeRequest, 'modelId'>>
        ) => apiClient.updateOutcome(outcomeId, payload),
        onSuccess: (outcome) => {
            queryClient.invalidateQueries({
                queryKey: ['model', outcome.modelId],
            })

            invalidateOutcomesQuery(outcome.modelId)
        },
        onError: (error) => {
            console.error('Error updating outcome', error)
        },
    })
}

export const useIsOutcomeBeingUpdated = (outcomeId: string) =>
    useIsMutating({
        mutationKey: ['useUpdateOutcome', outcomeId],
        exact: true,
    }) > 0

export const useDeleteOutcome = (modelId: string, outcomeId: string) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: ['useDeleteOutcome', outcomeId, modelId],
        mutationFn: async () => {
            await apiClient.deleteOutcome(outcomeId)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['model', modelId],
            })

            invalidateOutcomesQuery(modelId)
        },
        onError: (error) => {
            console.error('Error deleting outcome', error)
        },
    })
}

export const useIsOutcomeBeingDeleted = (outcomeId: string): boolean =>
    useIsMutating({
        mutationKey: ['useDeleteOutcome', outcomeId],
        exact: false,
    }) > 0

export const useUpdateOutcomeOrder = (modelId: string) =>
    useMutation({
        mutationKey: ['useUpdateOutcomeOrder', modelId],
        mutationFn: async (directives: { id: string; sortOrder: number }[]) => {
            await apiClient.updateOutcomeOrder(directives)
        },
        onSuccess: () => {
            invalidateOutcomesQuery(modelId)
        },
        onError: (error) => {
            console.error('Updating outcome order failed', error)
        },
    })

export const useIsOutcomeOrderBeingUpdated = (modelId: string) =>
    useIsMutating({
        mutationKey: ['useUpdateOutcomeOrder', modelId],
        exact: true,
    }) > 0

export const useRemoveOutcome = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (outcome: Outcome) => {
            await apiClient.deleteOutcome(outcome.id)
            return outcome
        },
        onSuccess: (outcome) => {
            queryClient.invalidateQueries({
                queryKey: ['model', outcome.modelId],
            })

            invalidateOutcomesQuery(outcome.modelId)
        },
        onError: (error) => {
            console.error('Error removing outcome', error)
        },
    })
}

export const useUpdateOutcomes = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (outcomes: Outcome[]) => {
            const results = []
            for (const outcome of outcomes) {
                const result = await apiClient.updateOutcome(outcome.id, {
                    sortOrder: outcome.sortOrder,
                    color: outcome.color,
                    label: outcome.label,
                })
                results.push(result)
            }
            return results
        },
        onSuccess: (outcomes) => {
            const modelIds = outcomes.map((outcome) => outcome.modelId)
            modelIds.forEach((modelId) => {
                queryClient.invalidateQueries({ queryKey: ['model', modelId] })
            })
        },
        onError: (error) => {
            console.error('Error updating outcomes', error)
        },
    })
}

/*
 * Model Questions
 */

export const useCreateQuestion = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async ({
            questionRequestData,
            criteria,
        }: {
            questionRequestData: CreateQuestionRequest
            criteria: OutcomeCriterion[]
        }) => {
            const newQuestion =
                await apiClient.createQuestion(questionRequestData)

            await Promise.all(
                criteria.map(async (criterion) => {
                    if (criterion.criterion) {
                        await apiClient.createOutcomeCriterion({
                            questionId: newQuestion.id,
                            outcomeId: criterion.outcomeId,
                            criterion: criterion.criterion,
                        })
                    }
                })
            )

            return newQuestion
        },
        onSuccess: ({ modelId }) => {
            queryClient.invalidateQueries({
                queryKey: ['model', modelId],
            })
        },
        onError: (error) => {
            console.error('Error creating question', error)
        },
    })
}

export const useUpdateQuestion = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async ({
            question,
            questionRequestData,
            criteria,
        }: {
            question: ModelWithRelations['questions'][number]
            questionRequestData: UpdateQuestionRequest
            criteria: OutcomeCriterion[]
        }) => {
            // Update question
            const updatedQuestion = await apiClient.updateQuestion(
                question.id,
                {
                    content: questionRequestData.content,
                    sortOrder: questionRequestData.sortOrder,
                    category1: questionRequestData.category1 ?? undefined,
                    category2: questionRequestData.category2 ?? undefined,
                    hint: questionRequestData.hint ?? undefined,
                }
            )

            // Update outcome criteria
            await Promise.all(
                criteria.map(async (next) => {
                    const prev = question.criteria.find(
                        (criterion) => criterion.id === next.id
                    )

                    if (prev) {
                        if (!next.criterion) {
                            await apiClient.deleteOutcomeCriterion(prev.id)
                        } else if (prev.criterion !== next.criterion) {
                            await apiClient.updateOutcomeCriterion(prev.id, {
                                criterion: next.criterion,
                            })
                        }

                        return
                    }

                    if (next.criterion) {
                        await apiClient.createOutcomeCriterion({
                            questionId: question.id,
                            outcomeId: next.outcomeId,
                            criterion: next.criterion,
                        })
                    }
                })
            )

            return updatedQuestion
        },
        onSuccess: (question) => {
            queryClient.invalidateQueries({
                queryKey: ['model', question.modelId],
            })
        },
        onError: (error) => {
            console.error('Error updating question', error)
        },
    })
}

export const useDeleteQuestion = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (question: Question) => {
            await apiClient.deleteQuestion(question.id)
            return question
        },
        onSuccess: (question) => {
            queryClient.invalidateQueries({
                queryKey: ['model', question.modelId],
            })
        },
        onError: (error) => {
            console.error('Error deleting question', error)
        },
    })
}

export const useCreateAssessment = () => {
    const draft = useAssessmentDraft()
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async () => {
            if (!draft.projectId || !draft.name) {
                throw new Error('Assessment draft is not complete')
            }
            const assessment = await apiClient.createAssessment({
                projectId: draft.projectId,
                modelId: draft.modelId,
                name: draft.name,
                sourceQuestionIds: draft.filteredQuestions.map(
                    (question) => question.id
                ),
                precedentDataRoomIds: draft.precedentDataRoomIds,
                assignedToId: draft.assignedToId,
            })

            return assessment
        },
        onSuccess: async (assessment) => {
            try {
                await apiClient.startAssessment(assessment.id)
            } catch (err) {
                /**
                 * Ignore start-up failures. In the future we may toast about
                 * it but for now we simply log and bounce.
                 */

                console.error(
                    `Starting assessment #${assessment.id} failed`,
                    err
                )
            }

            navigate(route('assessment', assessment.id))
        },
        onError: (error) => {
            console.error('Error creating assessment', error)
        },
    })
}

export const useDeleteAnswer = () =>
    useMutation({
        mutationFn: async (answer: Answer) => {
            await apiClient.deleteAnswer(answer.id)
            return answer
        },
        onSuccess: (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
        },
        onError: (error) => {
            console.error('Deleting answer failed', error)
        },
    })

export const useRunAnswer = () =>
    useMutation({
        mutationFn: async (answer: Answer) => {
            await apiClient.startAnswer(answer.id)

            return answer
        },
        onSuccess: (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
            invalidateAnswerQuery(answer.id)
        },
        onError: (error) => {
            console.error('Running answer failed', error)
        },
    })

export const useRunAssessment = () =>
    useMutation({
        mutationFn: async (assessment: Assessment) => {
            await apiClient.startAssessment(assessment.id)

            return assessment
        },
        onSuccess: (assessment) => {
            invalidateAssessmentQuery(assessment.id)
        },
        onError: (error) => {
            console.error('Running assessment failed', error)
        },
    })

export const useDeleteAssessment = () => {
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async (assessment: Assessment) => {
            await apiClient.deleteAssessment(assessment.id)

            return assessment
        },
        onSuccess: () => {
            navigate(
                {
                    pathname: route('assessments'),
                    search: window.location.search,
                },
                {
                    replace: true,
                }
            )
        },
        onError: (error) => {
            console.error('Deleting assessment failed', error)
        },
    })
}

export const useCreateDataRoom = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useCreateDataRoom'],
        mutationFn: async (name: string) => {
            return apiClient.createDataRoom({ name })
        },
        onSuccess: (dataRoom) => {
            navigate(route('dataRoom', dataRoom.id))
        },
    })
}

export const useCreateModel = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useCreateModel'],
        mutationFn: async (name: string) => {
            return apiClient.createModel({ name })
        },
        onSuccess: (model) => {
            navigate(route('model', model.id))
        },
    })
}

export const useDeleteModel = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationFn: async (model: Model) => {
            await apiClient.deleteModel(model.id)
            return model
        },
        onSuccess: (model) => {
            navigate(route('models'))
            invalidateModelQuery(model.id)
        },
        onError: (error) => {
            console.error('Deleting answer failed', error)
        },
    })
}

export const useCreateAnswer = (assessmentId: string) =>
    useMutation({
        mutationKey: ['useCreateAnswer', assessmentId],
        mutationFn: async (
            request: Omit<CreateAnswerRequest, 'assessmentId'>
        ) => {
            const answer = await apiClient.createAnswer({
                ...request,
                assessmentId,
            })

            return answer
        },
        onSuccess: async (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
        },
        onError: (error) => {
            console.error('Question creation failed', error)
        },
    })

export const useIsAnswerBeingCreated = (assessmentId: string) =>
    useIsMutating({
        mutationKey: ['useCreateAnswer', assessmentId],
        exact: true,
    }) > 0

export const useUpdateAnswer = (answer: Answer) =>
    useMutation({
        mutationKey: ['useUpdateAnswer', answer.id],
        mutationFn: async (request: UpdateAnswerRequest) => {
            await apiClient.updateAnswer(answer.id, request)

            return answer
        },
        onSuccess: (answer) => {
            invalidateAssessmentQuery(answer.assessmentId)
            invalidateAnswerQuery(answer.id)
        },
        onError: (error) => {
            console.error('Failed to set review status', error)
        },
    })

export const useIsAnswerBeingUpdated = (answerId: string) =>
    useIsMutating({
        mutationKey: ['useUpdateAnswer', answerId],
        exact: true,
    }) > 0

export const useCreateProject = () => {
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async (project: ProjectDraft) => {
            const newProject = await apiClient.createProject(project)
            return newProject
        },
        onSuccess: (project) => {
            navigate(route('project', project.id))
        },
        onError: (error) => {
            console.error('Project creation failed', error)
        },
    })
}

export const useUpdateProject = (projectId: string) => {
    return useMutation({
        mutationKey: ['useUpdateProject', projectId],
        mutationFn: async ({ project }: { project: ProjectDraft }) => {
            return apiClient.updateProject(projectId, project)
        },
        onSuccess: (project) => {
            invalidateProjectQuery(project.id)
        },
        onError: (error) => {
            console.error('Project update failed', error)
        },
    })
}

export const useDeleteProject = (projectId: string) => {
    const navigate = useNavigate()

    return useMutation({
        mutationKey: ['useDeleteProject', projectId],
        mutationFn: async () => {
            await apiClient.deleteProject(projectId)
        },
        onSuccess: () => {
            navigate(route('projects'))
        },
        onError: (error) => {
            console.error('Deleting project failed', error)
        },
    })
}

export const useDeleteDataRoom = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useDeleteDataRoom'],
        mutationFn: async (dataRoomId: string) => {
            await apiClient.deleteDataRoom(dataRoomId)
        },
        onSuccess: () => {
            navigate(route('dataRooms'))
        },
        onError: (error) => {
            console.error('Deleting data room failed', error)
        },
    })
}

export const useDeleteDocument = () =>
    useMutation({
        mutationKey: ['useDeleteDocument'],
        mutationFn: async ({
            documentId,
            projectId,
            dataRoomId,
        }: {
            documentId: string
            projectId?: string
            dataRoomId?: string
        }) => {
            await apiClient.deleteDataRoomDocument(documentId)

            return {
                documentId,
                projectId,
                dataRoomId,
            }
        },
        onSuccess: ({ projectId, dataRoomId }) => {
            if (projectId) {
                invalidateProjectQuery(projectId)
            }
            if (dataRoomId) {
                invalidateDataRoomQuery(dataRoomId)
            }
        },
        onError: (error) => {
            console.error('Failed to delete document', error)
        },
    })

export const useIsDeletingDocument = () =>
    useIsMutating({
        mutationKey: ['useDeleteDocument'],
        exact: true,
    }) > 0

export const useAcceptTermsAndConditions = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useAcceptTermsAndConditions'],
        mutationFn: async () => {
            await apiClient.acceptTermsAndConditions()
        },
        onSuccess: async () => {
            await invalidateUserQuery()
            navigate(route('assessments'))
        },
    })
}

export const useUploadReportTemplate = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useUploadReportTemplate'],
        mutationFn: async (file: File) => {
            const arrayBuffer = await file.arrayBuffer()
            const blob = new Blob([arrayBuffer], { type: file.type })
            return apiClient.uploadReportTemplate(blob, file.name)
        },
        onSuccess: (reportTemplate) => {
            navigate(route('reportTemplate', reportTemplate.id))
        },
        onError: (error: AxiosError) => {
            // erorr handling could be improved in the future
            invalidReportTemplateModal.pop({
                unknownError:
                    (
                        error?.response?.data as unknown as {
                            error?: string
                            cause?: string
                        }
                    )?.cause !== 'NO_AI_PROMPTS',
            })
            console.log(error)
        },
    })
}

export const useDeleteReportTemplate = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useDeleteReportTemplate'],
        mutationFn: async (reportTemplateId: string) => {
            await apiClient.deleteReportTemplate(reportTemplateId)
        },
        onSuccess: () => {
            navigate(route('reportTemplates'))
        },
    })
}

export const useUpdateReportTemplate = () => {
    return useMutation({
        mutationKey: ['useUpdateReportTemplate'],
        mutationFn: ({ id, name }: { id: string; name: string }) => {
            return apiClient.updateReportTemplate(id, { name })
        },
        onSuccess: (reportTemplate) => {
            invalidateReportTemplateQuery(reportTemplate.id)
        },
    })
}

export const useCreateReport = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useCreateReport'],
        mutationFn: ({
            name,
            assessmentId,
            reportTemplateId,
        }: {
            name: string
            assessmentId: string
            reportTemplateId: string
        }) => {
            return apiClient.createReport({
                name,
                assessmentId,
                reportTemplateId,
            })
        },
        onSuccess: async (report) => {
            await apiClient.startReport(report.id)
            navigate(route('report', report.id))
        },
    })
}

export const useRunReport = () => {
    return useMutation({
        mutationKey: ['useRunReport'],
        mutationFn: (reportId: string) => {
            return apiClient.startReport(reportId)
        },
        onSuccess: (report) => {
            invalidateReportQuery(report.id)
        },
    })
}

export const useDeleteReport = () => {
    const navigate = useNavigate()
    return useMutation({
        mutationKey: ['useDeleteReport'],
        mutationFn: (reportId: string) => {
            return apiClient.deleteReport(reportId)
        },
        onSuccess: () => {
            navigate(route('reports'))
        },
    })
}

export const useUpsertCategoryAssignee = () => {
    return useMutation({
        mutationFn: async (categoryAssignee: CategoryAssignee) => {
            const newCategoryAssignee =
                await apiClient.upsertCategoryAssignee(categoryAssignee)
            return newCategoryAssignee
        },
        onSuccess: (data: CategoryAssignee) => {
            invalidateCategoryAssigneesQuery(data.assessmentId)
        },
        onError: (error) => {
            console.error('Changing the category assignee failed', error)
        },
    })
}
