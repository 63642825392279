import React, { useCallback, useState } from 'react'
import { toaster } from 'toasterhea'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'
import { useCreateAnswer, useRunAnswer } from '../../utils/mutations'
import { QuestionForm } from '../Form/QuestionForm'
import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'

interface AddQuestionModalProps extends Omit<BaseModalProps, 'children'> {
    onConfirm: (question: string) => Promise<void>
}

const AddQuestionModal = ({ onConfirm, ...props }: AddQuestionModalProps) => {
    const [question, setQuestion] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Add a new question</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <QuestionForm
                        value={{ question }}
                        onChange={({ question }) => setQuestion(question)}
                        displayCategorySelection={false}
                    />
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsLoading(true)
                            onConfirm(question).then(() => {
                                setIsLoading(false)
                                props.onResolve?.()
                            })
                        }}
                        disabled={!question || isLoading}
                    >
                        {isLoading ? (
                            <EditorModalSpinnerContainer>
                                Saving
                                <EditorModalSpinner name="spinner" />
                            </EditorModalSpinnerContainer>
                        ) : (
                            'Save and run question'
                        )}
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

export const useAddQuestionModal = (assessmentId: string) => {
    const createAnswer = useCreateAnswer(assessmentId)
    const runAnswer = useRunAnswer()

    const addModal = toaster(AddQuestionModal, Layer.Modal)

    return useCallback(async () => {
        try {
            await addModal.pop({
                onConfirm: async (question) => {
                    const answer = await createAnswer.mutateAsync({ question })
                    await runAnswer.mutateAsync(answer)
                },
            })
        } catch (e) {
            // do nothing
        }
    }, [addModal])
}
