import { DataRoomDocument, Report, ReportTemplate } from 'silta-ai-backend'
import { apiClient } from 'utils/clients'

function download(fileName: string, data: Blob) {
    const url = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export async function downloadDocument(
    doc: Pick<DataRoomDocument, 'id' | 'originalFileName'>
) {
    download(
        doc.originalFileName,
        await apiClient.downloadDataRoomDocument(doc.id)
    )
}

export async function downloadReportTemplate(reportTemplate: ReportTemplate) {
    download(
        reportTemplate.originalFileName,
        await apiClient.downloadReportTemplate(reportTemplate.id)
    )
}

export async function downloadReport(report: Report) {
    download(`${report.name}.docx`, await apiClient.downloadReport(report.id))
}
