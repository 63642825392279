import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30vh 0;
`

const StyledIcon = styled(Icon)`
    margin-bottom: 12px;
    width: 40px;
    height: 40px;
    svg {
        width: 40px;
        height: 40px;
    }
`

export const TableDataLoading = () => {
    // Added a timeout to display the loading state to now have a flash of it when the table data is being loaded really fast
    const [display, setDisplay] = useState(false)
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDisplay(true)
            clearTimeout(timeoutId)
        }, 250)
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])
    return display ? (
        <Container>
            <StyledIcon name="spinnerBlueThin" />
            <p>Table content will show up shortly</p>
        </Container>
    ) : (
        <></>
    )
}
