import {
    keepPreviousData,
    useIsFetching,
    useQuery,
} from '@tanstack/react-query'
import { AssessmentListItem, GenericListParams } from 'silta-ai-backend'
import { apiClient, queryClient } from './clients'

const DEFAULT_STALE_TIME = 5 * 60000 // 5 minutes

function createListQuery<
    ListFn extends (params: GenericListParams) => Promise<unknown>,
>(queryKey: string, apiClientListFn: ListFn) {
    const boundListFn = apiClientListFn.bind(apiClient)

    return (params: Parameters<ListFn>[0] & { enabled?: boolean }) => {
        // Don't pass an empty string to `search` to avoid the silly `?search=`
        if (params.search?.length === 0) {
            params.search = undefined
        }

        return useQuery({
            queryKey: [queryKey, params],
            queryFn: () => boundListFn(params) as Awaited<ReturnType<ListFn>>,
            staleTime: DEFAULT_STALE_TIME,
            enabled: params.enabled,
        })
    }
}

export const useModelQuery = (id: string) =>
    useQuery({
        queryKey: ['model', id],
        queryFn: () => apiClient.getModel(id),
    })

export const useModel = (id: string) => useModelQuery(id).data

export const useAssesmentsForModelQuery = (id: string) =>
    useQuery({
        queryKey: ['assessmentsForModel', id],
        queryFn: () => apiClient.getAssessments({ modelId: id }),
    })

export const useAssesmentsForModel = (id: string) =>
    useAssesmentsForModelQuery(id).data

interface UseAssessmentQueryParams {
    assessmentId: string | undefined
}

export const useAssessmentsForProjectQuery = (projectId?: string) =>
    useQuery<AssessmentListItem[]>({
        queryKey: ['useAssessmentsForProjectQuery', projectId],
        queryFn: async () => {
            if (!projectId) {
                return []
            }

            return apiClient.getAssessments({ projectId })
        },
        staleTime: DEFAULT_STALE_TIME,
    })

export const useIsFetchingAssessmentsForProject = (projectId?: string) =>
    useIsFetching({ queryKey: ['useAssessmentsForProjectQuery', projectId] }) >
    0

export const useAssessmentQuery = (params: UseAssessmentQueryParams) => {
    const { assessmentId } = params

    return useQuery({
        queryKey: ['useAssessmentQuery', assessmentId],
        queryFn: async () => {
            if (!assessmentId) {
                return null
            }

            return apiClient.getAssessment(assessmentId)
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateModelQuery = (modelId: string | undefined) => {
    if (modelId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useModelQuery'],
            exact: false,
        })
    }
    return queryClient.invalidateQueries({
        queryKey: ['useModelQuery', modelId],
        exact: true,
    })
}

export const invalidateAssessmentQuery = (assessmentId: string | undefined) => {
    if (assessmentId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useAssessmentQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useAssessmentQuery', assessmentId],
        exact: true,
    })
}

export function useOutcomesQuery(modelId: string, params?: GenericListParams) {
    return useQuery({
        queryKey: ['useOutcomesQuery', modelId, params],
        queryFn: () => apiClient.getOutcomes({ modelId, ...(params || {}) }),
        staleTime: DEFAULT_STALE_TIME,
        placeholderData: keepPreviousData,
    })
}

export function invalidateOutcomesQuery(modelId: string) {
    return queryClient.invalidateQueries({
        queryKey: ['useOutcomesQuery', modelId],
        exact: false,
    })
}

export const useAssessmentsQuery = createListQuery(
    'useAssessmentsQuery',
    apiClient.getAssessments
)

export const useReportsQuery = createListQuery(
    'useReportsQuery',
    apiClient.getReports
)

export const useReportTemplatesQuery = createListQuery(
    'useReportTemplatesQuery',
    apiClient.getReportTemplates
)

export const useProjectsQuery = createListQuery(
    'useProjectsQuery',
    apiClient.getProjects
)

interface UseProjectQueryParams {
    projectId: string | undefined
}

export const useProjectQuery = (params: UseProjectQueryParams) => {
    const { projectId } = params

    return useQuery({
        queryKey: ['useProjectQuery', projectId],
        queryFn: async () => {
            if (!projectId) {
                return null
            }

            const project = await apiClient.getProject(projectId)
            const dataRoomDocuments = await apiClient.getDataRoomDocuments({
                dataRoomId: project.dataRoomId,
            })
            return { ...project, dataRoomDocuments }
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateProjectQuery = (projectId: string | undefined) => {
    if (projectId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useProjectQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useProjectQuery', projectId],
        exact: true,
    })
}

export const useDataRoomDocumentsQuery = (dataRoomId: string | undefined) => {
    return useQuery({
        queryKey: ['useDataRoomDocuments', dataRoomId],
        queryFn: () => {
            if (!dataRoomId) return null
            return apiClient.getDataRoomDocuments({ dataRoomId })
        },
    })
}

export const invalidateDataRoomDocumentsQuery = (
    dataRoomId: string | undefined
) => {
    if (dataRoomId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useDataRoomDocumentsQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useDataRoomDocumentsQuery', dataRoomId],
        exact: true,
    })
}

export const useDataRoomQuery = (dataRoomId: string) => {
    return useQuery({
        queryKey: ['useDataRoomQuery', dataRoomId],
        queryFn: () => apiClient.getDataRoom(dataRoomId),
    })
}

export const invalidateDataRoomQuery = (dataRoomId: string | undefined) => {
    if (dataRoomId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useDataRoomQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useDataRoomQuery', dataRoomId],
        exact: true,
    })
}

export const useDataRoomsQuery = createListQuery(
    'useDataRoomsQuery',
    apiClient.getDataRooms
)

export const useModelsQuery = createListQuery(
    'useModelsQuery',
    apiClient.getModels
)

export const useUsersQuery = createListQuery(
    'useUsersQuery',
    apiClient.getUsers
)

export const useTeamsQuery = createListQuery(
    'useTeamsQuery',
    apiClient.getTeams
)

interface UseUserQueryParams {
    enabled?: boolean
}

export const useUserQuery = ({ enabled = true }: UseUserQueryParams = {}) =>
    useQuery({
        queryKey: ['useUserQuery'],
        queryFn: () => apiClient.getUser(),
        staleTime: DEFAULT_STALE_TIME,
        enabled,
    })

export const invalidateUserQuery = () => {
    return queryClient.invalidateQueries({
        queryKey: ['useUserQuery'],
        exact: false,
    })
}

interface UseAnswerQueryParams {
    answerId: string | undefined
}

export const useAnswerQuery = (params: UseAnswerQueryParams) => {
    const { answerId } = params

    return useQuery({
        queryKey: ['useAnswerQuery', answerId],
        queryFn: async () => {
            if (!answerId) {
                return null
            }

            return apiClient.getAnswer(answerId)
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateAnswerQuery = (answerId: string | undefined) => {
    if (!answerId) {
        return queryClient.invalidateQueries({
            queryKey: ['useAnswerQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useAnswerQuery', answerId],
        exact: true,
    })
}

export const useReportTemplateQuery = (reportTemplateId: string) =>
    useQuery({
        queryKey: ['useReportTemplateQuery', reportTemplateId],
        queryFn: () => apiClient.getReportTemplate(reportTemplateId),
    })

export const invalidateReportTemplateQuery = (reportTemplateId: string) => {
    return queryClient.invalidateQueries({
        queryKey: ['useReportTemplateQuery', reportTemplateId],
        exact: true,
    })
}

export const invalidateReportTemplatesQuery = () => {
    return queryClient.invalidateQueries({
        queryKey: ['useReportTemplatesQuery'],
    })
}

export const useReportQuery = (reportId: string) =>
    useQuery({
        queryKey: ['useReportQuery', reportId],
        queryFn: () => apiClient.getReport(reportId),
    })

export const invalidateReportQuery = (reportId: string) => {
    return queryClient.invalidateQueries({
        queryKey: ['useReportQuery', reportId],
        exact: true,
    })
}

export const useCategoryAssigneesQuery = (assessmentId: string) => {
    return useQuery({
        queryKey: ['useCategoryAssigneesQuery', assessmentId],
        queryFn: () => apiClient.getCategoryAssignees({ assessmentId }),
    })
}

export const invalidateCategoryAssigneesQuery = (assessmentId: string) => {
    return queryClient.invalidateQueries({
        queryKey: ['useCategoryAssigneesQuery', assessmentId],
    })
}

export const resetAllQueries = () => {
    return queryClient.resetQueries()
}
