import uniqueId from 'lodash/uniqueId'
import React, { Fragment, ReactNode, useEffect, useMemo, useRef } from 'react'
import reactStringReplace from 'react-string-replace'
import {
    Answer,
    AnswerWithRelations,
    AssessmentWithRelations,
    Outcome,
} from 'silta-ai-backend'
import { FilterKeys } from 'types/filters'
import { AssessmentAnswersFilter, expandFilter } from './filters'
import { ReferenceMatch, extractReferences } from './references'
import { apiClient } from './clients'
import { invalidateAnswerQuery } from './queries'

interface UseFilteredAnswersParams {
    answers: AssessmentWithRelations['answers']
    filter: AssessmentAnswersFilter
    outcomesById: Partial<Record<string, Outcome>>
}

/**
 * Takes an array of answers items and returns an array of items
 * matching given filter.
 */
export function useFilteredAnswers(
    params: UseFilteredAnswersParams
): AssessmentWithRelations['answers'] {
    const { answers, filter, outcomesById } = params

    return useMemo(() => {
        if (Object.keys(filter).length === 0) {
            return answers
        }

        const result: AssessmentWithRelations['answers'] = []

        const expandedFilter = expandFilter(
            filter,
            AssessmentAnswersFilter.items.map(({ key }) => key)
        )

        function eligible(
            key: FilterKeys<AssessmentAnswersFilter>,
            value: string | undefined
        ) {
            if (!Object.keys(expandedFilter[key].value).length) {
                return true
            }

            const field = expandedFilter[key]

            if (!value) {
                return field.operator !== 'is'
            }

            return field.operator === 'is'
                ? field.value[value] === true
                : !field.value[value]
        }

        for (const answer of answers) {
            let include = true

            include =
                include &&
                eligible(
                    'category',
                    answer.sourceQuestion?.category1 || undefined
                )

            /** @todo  */
            include = include && eligible('lead', undefined)

            include = include && eligible('status', answer.status)

            include = include && eligible('reviewStatus', answer.reviewStatus)

            include =
                include &&
                eligible(
                    'outcome',
                    answer.outcomeId
                        ? outcomesById[answer.outcomeId]?.label
                        : undefined
                )

            if (include) {
                result.push(answer)
            }
        }

        return result
    }, [answers, filter, outcomesById])
}

export function useExtractedReferences(
    answer?: AssessmentWithRelations['answers'][0] | null
) {
    const { references, matches } = useMemo(() => {
        if (!answer) {
            return {
                references: [],
                matches: [],
            }
        }
        return extractReferences(answer)
    }, [answer?.content])
    return { references, matches }
}

interface UseAnswerReferencesProps {
    answer: AssessmentWithRelations['answers'][0]
    referenceRenderer(match: ReferenceMatch): ReactNode
}

/**
 * Extracts references from an answer and composes an array
 * of formatted paragraphs.
 * @returns An object containing paragraphs, references, and content key.
 */
export function useAnswerReferences({
    answer,
    referenceRenderer,
}: UseAnswerReferencesProps) {
    const referenceRendererRef = useRef(referenceRenderer)

    if (referenceRendererRef.current !== referenceRenderer) {
        referenceRendererRef.current = referenceRenderer
    }

    const { references, matches } = useExtractedReferences(answer)

    const paragraphs = useMemo(() => {
        const paragraphs = [answer.content, answer.outcomeDetails]
            .filter(Boolean)
            .map((paragraph) => {
                let formattedContent: ReactNode[] = [paragraph]

                matches.forEach((match) => {
                    formattedContent = reactStringReplace(
                        formattedContent,
                        match.originalText,
                        () => (
                            <Fragment
                                key={uniqueId(`Ref${match.reference.index}-`)}
                            >
                                {referenceRendererRef.current(match)}
                            </Fragment>
                        )
                    )
                })

                return formattedContent
            })

        return paragraphs
    }, [answer.content])

    return { references, paragraphs }
}

export function getReviewedCount(answers: Answer[]) {
    return answers.reduce(
        (sum, { reviewStatus }) =>
            sum +
            Number(
                reviewStatus !== 'NotReadyForReview' &&
                    reviewStatus !== 'ReadyForReview'
            ),
        0
    )
}

export function useRunningAnswerPollEffect(answer: AnswerWithRelations | null) {
    const { id: answerId = undefined } = answer || {}

    const isRunning = answer?.status === 'running'

    useEffect(
        function pollAnswerIfRunningAnswers() {
            let mounted = true

            if (answerId && isRunning) {
                ;(async () => {
                    for (;;) {
                        try {
                            const innerAnswer =
                                await apiClient.getAnswer(answerId)

                            if (!mounted) {
                                return
                            }

                            if (innerAnswer.status !== 'running') {
                                invalidateAnswerQuery(answerId)

                                return
                            }
                        } catch (e) {
                            console.warn('Getting answer failed', e)
                        }

                        await new Promise<void>((resolve) => {
                            setTimeout(resolve, 5000)
                        })

                        if (!mounted) {
                            return
                        }
                    }
                })()
            }

            return () => {
                mounted = false
            }
        },
        [answerId, isRunning]
    )
}
