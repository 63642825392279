import { Layout } from 'components/Layout'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from 'styled-components'
import { toaster } from 'toasterhea'
import { useRequiredParam } from 'utils/misc'
import {
    useIsFetchingAssessmentsForProject,
    useProjectQuery,
} from 'utils/queries'
import { DotdotdotButton } from './DotdotdotButton'
import { Dropdown, DropdownContent } from './Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { HorizontalLoadingIndicator } from './HorizontalLoadingIndicator'
import { Icon } from './Icon'
import { ListTopBar } from './ListTopBar'
import {
    ShowSidebarProvider,
    Sidebar,
    SidebarHost,
    SidebarSection,
    useShowSidebar,
} from './Sidebar'
import { SidebarToggle } from './SidebarToggle'
import { BreadcrumbsToolbar } from './Toolbar/BreadcrumbsToolbar'
import { route } from '../utils/routes'
import { EntityProperties, EntityProps } from './EntityProperties'
import { useDeleteProject, useIsDeletingDocument } from '../utils/mutations'
import { ConfirmationModal } from './modals/ConfirmationModal'
import { Layer } from '../utils/layers'
import { AccessDeniedHandler } from './AccessDeniedHandler'
import { useEditProjectModal } from './modals/EditProjectModal'

const LayoutInner = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;

    ${SidebarHost} {
        flex-grow: 1;
    }
`

function DoSearch() {}

export function ProjectPageLayout() {
    const [displaySidebar, setDisplaySidebar] = useState(false)

    const projectId = useRequiredParam('id')

    const projectQuery = useProjectQuery({ projectId })

    const { isFetching, data: project } = projectQuery

    const deleteProject = useDeleteProject(projectId)

    const isFetchingAssessments = useIsFetchingAssessmentsForProject(projectId)

    const isDeletingDocument = useIsDeletingDocument()

    const confirmModal = toaster(ConfirmationModal, Layer.Modal)

    const triggerDeleteDocument = useCallback(async () => {
        try {
            const confirmed = await confirmModal.pop({
                title: 'Delete Project?',
                content: `Are you sure you want to delete the project “${project?.name}”?`,
                confirmButtonText: 'Delete Project',
                cancelButtonText: 'Cancel',
            })
            if (confirmed) {
                deleteProject.mutate()
            }
        } catch (e) {
            // do nothing
        }
    }, [project, deleteProject])

    const triggerEditProject = useEditProjectModal(projectId)

    return (
        <AccessDeniedHandler onSearch={DoSearch}>
            <ShowSidebarProvider value={displaySidebar}>
                <Layout>
                    <LayoutInner>
                        <ListTopBar onSearch={DoSearch} />
                        <HorizontalLoadingIndicator
                            loading={
                                isFetching ||
                                isFetchingAssessments ||
                                isDeletingDocument ||
                                deleteProject.isPending
                            }
                        />
                        {!!project && (
                            <>
                                <BreadcrumbsToolbar
                                    currentPageName={
                                        project.customId ?? project.name
                                    }
                                    listPage={{
                                        name: 'All projects',
                                        route: route('projects'),
                                        icon: 'grid',
                                    }}
                                    lhsAux={
                                        <>
                                            <Dropdown
                                                trigger={({ onClick }) => (
                                                    <DotdotdotButton
                                                        onClick={onClick}
                                                    />
                                                )}
                                            >
                                                {(dismiss) => (
                                                    <DropdownContent>
                                                        <SelectBasicItem
                                                            type="button"
                                                            onClick={() => {
                                                                dismiss()
                                                                triggerEditProject(
                                                                    project
                                                                )
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="edit" />
                                                            </SelectBasicItemIconWrap>
                                                            Edit project
                                                        </SelectBasicItem>
                                                        <SelectBasicItem
                                                            type="button"
                                                            onClick={() => {
                                                                dismiss()
                                                                triggerDeleteDocument()
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="trash" />
                                                            </SelectBasicItemIconWrap>
                                                            Delete project
                                                        </SelectBasicItem>
                                                    </DropdownContent>
                                                )}
                                            </Dropdown>
                                        </>
                                    }
                                    rhsAux={
                                        <SidebarToggle
                                            value={displaySidebar}
                                            onClick={() => {
                                                setDisplaySidebar((c) => !c)
                                            }}
                                        />
                                    }
                                />
                                <Outlet />
                            </>
                        )}
                        {!isFetching && !project && <>Not found</>}
                    </LayoutInner>
                </Layout>
            </ShowSidebarProvider>
        </AccessDeniedHandler>
    )
}

const MainColumn = styled.div`
    flex-grow: 1;
`

interface ProjectInnerLayoutProps {
    projectId: string
    children?: ReactNode
    toolbars?: ReactNode
}

export function ProjectInnerLayout({
    projectId,
    children,
    toolbars,
}: ProjectInnerLayoutProps) {
    const displaySidebar = useShowSidebar()

    const { data: project = null } = useProjectQuery({ projectId })

    const sidebarProperties = useMemo<EntityProps[]>(() => {
        return project
            ? ([
                  project.country
                      ? {
                            name: 'Country',
                            value: {
                                content: project.country,
                                icon: <Icon name="globe" />,
                            },
                        }
                      : undefined,
                  project.assignedTo
                      ? {
                            name: 'Project Officer',
                            value: {
                                content: project.assignedTo.name || 'N/A',
                                icon: <Icon name="userCircle" />,
                            },
                        }
                      : undefined,
                  project.sector
                      ? {
                            name: 'Sector',
                            value: {
                                content: project.sector,
                                icon: <Icon name="parthenon" />,
                            },
                        }
                      : undefined,
                  {
                      name: 'Data Room',
                      value: {
                          content: (
                              <span className="no-wrap">
                                  {`${project.dataRoomDocuments.length} documents`}
                              </span>
                          ),
                          icon: <Icon name="folder" />,
                      },
                  },
              ].filter(Boolean) as EntityProps[])
            : []
    }, [project])

    if (!project) {
        return null
    }

    return (
        <>
            {toolbars}
            <SidebarHost>
                <MainColumn>{children}</MainColumn>
                {displaySidebar && (
                    <Sidebar>
                        <SidebarSection title="Properties" emphasized>
                            <EntityProperties entityProps={sidebarProperties} />
                        </SidebarSection>
                    </Sidebar>
                )}
            </SidebarHost>
        </>
    )
}
