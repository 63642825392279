import React, { useMemo } from 'react'
import cx from 'classnames'
import { AssessmentAnswerCount } from 'components/AssessmentAnswerCount'
import { AssessmentInnerLayout } from 'components/AssessmentPageLayout'
import { BarChart, BarChartEntry } from 'components/BarChart'
import { Icon } from 'components/Icon'
import { OutcomeTileGrid, OutcomeTileLink } from 'components/OutcomeTile'
import { SkeletonParagraph } from 'components/SkeletonParagraph'
import { Table } from 'components/Table'
import { ToolbarButton } from 'components/Toolbar'
import { AssessmentNavToolbar } from 'components/Toolbar/AssessmentNavToolbar'
import { useNavigate } from 'react-router-dom'
import {
    Answer,
    AssessmentWithRelations,
    CategoryAssigneeWithRelations,
} from 'silta-ai-backend'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { getReviewedCount } from 'utils/answers'
import {
    useAssessmentOutcomes,
    useGetDataRoomsWithDocumentsNewerThanAssessment,
    useIsRunning,
} from 'utils/assessments'
import { useExtract, useRequiredParam } from 'utils/misc'
import {
    useAssessmentQuery,
    useCategoryAssigneesQuery,
    useUsersQuery,
} from 'utils/queries'
import { route } from 'utils/routes'
import { Separator } from '../components/Separator'
import { InfoBox } from '../components/InfoBox'
import { TextLink } from '../components/TextLink'
import { Button } from '../components/Button'
import {
    useRunAssessment,
    useRunAnswer,
    useUpsertCategoryAssignee,
} from '../utils/mutations'
import { FilterValue } from '../types/filters'
import { useCreateReportModal } from '../components/modals/CreateReportModal'
import { UserSelection } from '../components/UserSelection'

const UNCATEGORIZED = 'Uncategorized'

const Pad = styled.div`
    padding: 0 40px;
    max-width: 720px;

    > h2 {
        font-weight: var(--ai-typography-caption-emphasized-font-weight);
        margin-bottom: 16px;
    }
`

const Root = styled.div`
    display: grid;
    gap: 40px;
    padding: 40px 0 96px;

    strong {
        font-weight: 500;
    }
`

const InfoBoxItemList = styled.ul`
    padding: 0;
    margin: 16px 0 0;
    list-style-type: none;
    li {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            content: '•';
            margin-right: 3px;
            color: ${themeVariables.colors.brand};
        }
        a {
            font-weight: 400 !important;
        }
    }
`

const getCategoryFilter = (
    category: string,
    categoriesAndAnswers: [string, Answer[]][]
): FilterValue => ({
    operator: category === UNCATEGORIZED ? 'is not' : 'is',
    value:
        category === UNCATEGORIZED
            ? categoriesAndAnswers
                  .map((categoryAndAnswer) => categoryAndAnswer[0])
                  .filter((cat) => cat !== UNCATEGORIZED)
            : [category],
})

function useAssessmentAnswersByMainCategory(
    assessment: AssessmentWithRelations | null
) {
    return useExtract(assessment, (assessment) => {
        if (!assessment) {
            return []
        }

        const groups: Record<string, Answer[]> = {}

        for (const answer of assessment.answers) {
            const category = answer.sourceQuestion?.category1

            const categoryName = category || UNCATEGORIZED

            const current = groups[categoryName] || []

            current.push(answer)

            groups[categoryName] = current
        }

        const result: [string, Answer[]][] = []

        for (const [category, answers] of Object.entries(groups)) {
            result.push([category, answers])
        }

        // sort it ascending, but leave "Uncategorized" always at the end
        return result.sort(([a], [b]) => {
            if (a === UNCATEGORIZED) return 1
            if (b === UNCATEGORIZED) return -1
            return a.localeCompare(b)
        })
    })
}

export function AssessmentShowSummaryPage() {
    const assessmentId = useRequiredParam('id')

    const { data: assessment = null } = useAssessmentQuery({ assessmentId })

    const outcomes = useAssessmentOutcomes(assessment)

    const categories = useAssessmentAnswersByMainCategory(assessment)

    const { data: categoryAssignees = [] } =
        useCategoryAssigneesQuery(assessmentId)

    const navigate = useNavigate()

    const dataRoomsWithNewDocuments =
        useGetDataRoomsWithDocumentsNewerThanAssessment(assessmentId)

    const isRunning = useIsRunning(assessment || null)

    const runAssessment = useRunAssessment()

    const runAnswer = useRunAnswer()

    const createReport = useCreateReportModal()

    const upsertCategoryAssignee = useUpsertCategoryAssignee()

    const { data: allUsers = [] } = useUsersQuery({})

    const categoryUsers = useMemo(() => {
        const categoriesLookUptable: Record<
            string,
            { id: string; name: string }
        > = {}
        categoryAssignees.forEach(
            (categoryAssigneeObject: CategoryAssigneeWithRelations) => {
                categoriesLookUptable[categoryAssigneeObject.category] =
                    categoryAssigneeObject.user
            }
        )
        return categoriesLookUptable
    }, [categoryAssignees])

    const answersWithErrors = assessment?.answers.filter((answer) => {
        return answer.status === 'error'
    })

    const rows = useExtract(
        categories,
        (categories) =>
            categories.map(([category, answers]) => {
                const reviewedCount = getReviewedCount(answers)

                return {
                    assessmentId,
                    category,
                    questionCount: answers.length,
                    lead: 'n/a',
                    reviewProgress: [
                        {
                            color: themeVariables.palettes.info600,
                            force: true,
                            value: reviewedCount,
                            onClick: (e) => {
                                e.stopPropagation()

                                navigate(
                                    route('assessmentAnswers', assessmentId, {
                                        category: getCategoryFilter(
                                            category,
                                            categories
                                        ),
                                        reviewStatus: ['Approved', 'Rejected'],
                                    })
                                )
                            },
                        },
                        {
                            value: answers.length - reviewedCount,
                            onClick: (e) => {
                                e.stopPropagation()

                                navigate(
                                    route('assessmentAnswers', assessmentId, {
                                        category: getCategoryFilter(
                                            category,
                                            categories
                                        ),
                                        reviewStatus: {
                                            operator: 'is not',
                                            value: ['Approved', 'Rejected'],
                                        },
                                    })
                                )
                            },
                        },
                    ] as BarChartEntry[],
                    outcome: (outcomes.length
                        ? [
                              ...(outcomes.map(({ id, label, color }) => ({
                                  color,
                                  onClick: (e) => {
                                      e.stopPropagation()

                                      navigate(
                                          route(
                                              'assessmentAnswers',
                                              assessmentId,
                                              {
                                                  category: getCategoryFilter(
                                                      category,
                                                      categories
                                                  ),
                                                  outcome: [label],
                                              }
                                          )
                                      )
                                  },
                                  value: answers.reduce(
                                      (sum, answer) =>
                                          sum + Number(answer.outcomeId === id),
                                      0
                                  ),
                              })) satisfies BarChartEntry[]),
                              {
                                  value: answers.reduce(
                                      (sum, answer) =>
                                          sum + Number(!answer.outcomeId),
                                      0
                                  ),
                                  onClick: (e) => {
                                      e.stopPropagation()

                                      navigate(
                                          route(
                                              'assessmentAnswers',
                                              assessmentId,
                                              {
                                                  category: getCategoryFilter(
                                                      category,
                                                      categories
                                                  ),
                                                  outcome: {
                                                      operator: 'is not',
                                                      value: outcomes.map(
                                                          ({ label }) => label
                                                      ),
                                                  },
                                              }
                                          )
                                      )
                                  },
                              },
                          ]
                        : []) as BarChartEntry[],
                }
            }),
        [outcomes]
    )

    if (!assessment) {
        return null
    }

    return (
        <AssessmentInnerLayout
            assessmentId={assessmentId}
            toolbars={
                <AssessmentNavToolbar
                    assessmentId={assessmentId}
                    rhsAux={
                        <ToolbarButton
                            onClick={() => {
                                createReport(assessmentId)
                            }}
                            prepend={<Icon name="plusSmall" />}
                        >
                            Create report
                        </ToolbarButton>
                    }
                    answersCount={assessment.answers.length}
                    reportsCount={assessment._count.reports}
                />
            }
        >
            <Root>
                <Pad>
                    <h1>{assessment.name}</h1>
                    <div>
                        <div>
                            {assessment.project.customId
                                ? `${assessment.project.customId} - `
                                : ''}
                            {assessment.project.name}
                        </div>
                    </div>
                </Pad>
                {dataRoomsWithNewDocuments.length > 0 && (
                    <div className={cx('p-l-40', 'p-r-40')}>
                        <InfoBox
                            backgroundColor={
                                themeVariables.palettes.attention100
                            }
                            borderColor={themeVariables.palettes.attention600}
                            iconColor={themeVariables.palettes.attention600}
                            iconName="info"
                            textColor={themeVariables.colors.primary}
                        >
                            <div>
                                The following data rooms have been updated since
                                this assessment was generated. Some answers may
                                be outdated.
                                <InfoBoxItemList>
                                    {dataRoomsWithNewDocuments.map(
                                        (dataRoom) => (
                                            <li key={dataRoom.id}>
                                                <TextLink
                                                    className="answerErrorLink"
                                                    to={
                                                        assessment.project
                                                            .dataRoomId ===
                                                        dataRoom.id
                                                            ? route(
                                                                  'projectDataRoom',
                                                                  assessment
                                                                      .project
                                                                      .id
                                                              )
                                                            : route(
                                                                  'dataRoom',
                                                                  dataRoom.id
                                                              )
                                                    }
                                                >
                                                    {dataRoom.name}
                                                </TextLink>
                                            </li>
                                        )
                                    )}
                                </InfoBoxItemList>
                                <Button
                                    $variant="secondary"
                                    className={cx(
                                        'd-flex',
                                        'align-items-center',
                                        'g-5',
                                        'm-t-25'
                                    )}
                                    disabled={
                                        runAssessment.isPending || isRunning
                                    }
                                    onClick={() => {
                                        runAssessment.mutate(assessment)
                                    }}
                                >
                                    <Icon name="repeat" />
                                    Re-run assessment
                                </Button>
                            </div>
                        </InfoBox>
                    </div>
                )}

                {(assessment.summaryStatus === 'error' ||
                    (answersWithErrors && answersWithErrors.length > 0)) && (
                    <div className={cx('p-l-40', 'p-r-40')}>
                        <InfoBox
                            backgroundColor={
                                themeVariables.palettes.attention100
                            }
                            borderColor={themeVariables.palettes.attention600}
                            iconColor={themeVariables.palettes.attention600}
                            iconName="info"
                            textColor={themeVariables.colors.primary}
                        >
                            <div>
                                {!answersWithErrors && (
                                    <p>
                                        An error occurred while generating the
                                        summary.
                                    </p>
                                )}
                                {answersWithErrors &&
                                    answersWithErrors.length >= 1 && (
                                        <span>
                                            {answersWithErrors.length === 1 ? (
                                                <React.Fragment>
                                                    <TextLink
                                                        to={route(
                                                            'assessmentAnswers',
                                                            assessmentId,
                                                            {
                                                                status: [
                                                                    'error',
                                                                ],
                                                            }
                                                        )}
                                                    >
                                                        1 question
                                                    </TextLink>{' '}
                                                    failed due to errors. You
                                                    can try re-running it.
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <TextLink
                                                        to={route(
                                                            'assessmentAnswers',
                                                            assessmentId,
                                                            {
                                                                status: [
                                                                    'error',
                                                                ],
                                                            }
                                                        )}
                                                    >
                                                        {
                                                            answersWithErrors.length
                                                        }{' '}
                                                        questions
                                                    </TextLink>{' '}
                                                    failed due to errors. You
                                                    can try re-running them.
                                                </React.Fragment>
                                            )}
                                        </span>
                                    )}
                                <Button
                                    $variant="secondary"
                                    className={cx(
                                        'd-flex',
                                        'align-items-center',
                                        'g-5',
                                        'm-t-25'
                                    )}
                                    disabled={
                                        runAssessment.isPending || isRunning
                                    }
                                    onClick={() => {
                                        answersWithErrors?.forEach((answer) => {
                                            runAnswer.mutate(answer)
                                        })
                                    }}
                                >
                                    <Icon name="repeat" />
                                    Re-run failed question
                                    {answersWithErrors &&
                                    answersWithErrors.length > 1
                                        ? 's'
                                        : ''}
                                </Button>
                            </div>
                        </InfoBox>
                    </div>
                )}

                {outcomes.length > 0 && (
                    <div>
                        <Pad>
                            <h3 className="m-b-30">Priority Level Overview</h3>
                        </Pad>
                        <OutcomeTileGrid>
                            {outcomes.map((outcome) => (
                                <OutcomeTileLink
                                    key={outcome.id}
                                    to={route(
                                        'assessmentAnswers',
                                        assessmentId,
                                        {
                                            outcome: [outcome.label],
                                        }
                                    )}
                                    color={outcome.color}
                                    label={`${outcome.label} answers`}
                                    answerCount={
                                        <AssessmentAnswerCount
                                            assessment={assessment}
                                            isEligible={(answer) =>
                                                answer.outcomeId === outcome.id
                                            }
                                        />
                                    }
                                />
                            ))}
                        </OutcomeTileGrid>

                        <div className={cx('p-l-40', 'p-r-40')}>
                            <Separator className={cx('m-t-50', 'm-b-10')} />
                        </div>
                    </div>
                )}
                {rows.length > 0 && (
                    <div>
                        <Pad>
                            <h3 className="m-b-30">
                                Categories Assessment Overview
                            </h3>
                        </Pad>
                        <div className={cx('p-l-40', 'p-r-40')}>
                            <Table
                                columns={[
                                    {
                                        key: 'category',
                                        title: 'Type',
                                        render: (row) => (
                                            <strong>{row.category}</strong>
                                        ),
                                    },
                                    {
                                        key: 'questionCount',
                                        title: 'Questions',
                                        width: 'minmax(auto, 10%)',
                                    },
                                    {
                                        key: 'lead',
                                        title: 'Team leader',
                                        width: 'minmax(auto, 15%)',
                                        render: (row) => (
                                            <UserSelection
                                                selectedUser={
                                                    categoryUsers[
                                                        row.category as string
                                                    ]?.name || 'n/a'
                                                }
                                                users={allUsers}
                                                onUserSelect={async (
                                                    userId
                                                ) => {
                                                    await upsertCategoryAssignee.mutateAsync(
                                                        {
                                                            assessmentId,
                                                            category:
                                                                row.category,
                                                            userId,
                                                        }
                                                    )
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        key: 'reviewProgress',
                                        title: 'Review progress',
                                        render: (row) => (
                                            <BarChart
                                                data={row.reviewProgress}
                                            />
                                        ),
                                    },
                                    {
                                        key: 'outcome',
                                        title: 'Outcome',
                                        render: (row) =>
                                            row.outcome.length ? (
                                                <BarChart
                                                    fallbackEntry={{
                                                        label: 'n/a',
                                                    }}
                                                    data={row.outcome}
                                                />
                                            ) : (
                                                ''
                                            ),
                                    },
                                    {
                                        key: 'actions',
                                        render: (_, hovered) => (
                                            <ToolbarButton
                                                style={{
                                                    visibility: hovered
                                                        ? 'visible'
                                                        : 'hidden',
                                                }}
                                                disabled
                                                elevation="none"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                                prepend={<Icon name="report" />}
                                            >
                                                View report
                                            </ToolbarButton>
                                        ),
                                    },
                                ]}
                                data={rows}
                                stickyHeader
                                onRowClick={(row) =>
                                    navigate(
                                        route(
                                            'assessmentAnswers',
                                            row.assessmentId,
                                            {
                                                category: getCategoryFilter(
                                                    row.category,
                                                    categories
                                                ),
                                            }
                                        )
                                    )
                                }
                                noClickColumnKeys={['lead']}
                            />
                        </div>
                    </div>
                )}
                <Pad>
                    <h2>Summary of the assessment</h2>
                    {assessment.summaryStatus === 'new' && (
                        <p>
                            A summary for this assessment does not exist. Re-run
                            at least one answer to generate it.
                        </p>
                    )}
                    {assessment.summaryStatus === 'running' && (
                        <SkeletonParagraph
                            fontSize={14}
                            length={1100}
                            lineLengthRandomness={40}
                        />
                    )}
                    {assessment.summaryStatus === 'ready' &&
                        assessment.summary
                            ?.split('\n')
                            .map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                </Pad>
            </Root>
        </AssessmentInnerLayout>
    )
}
