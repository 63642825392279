import React, { useMemo } from 'react'
import { Answer } from 'silta-ai-backend'
import cx from 'classnames'
import { useExtract } from '../utils/misc'
import { getReviewedCount } from '../utils/answers'
import { CircularProgressIndicator } from './CircularProgressIndicator'
import { getColorForProgress } from '../utils/assessments'

export function AssessmentProgress<T extends { answers: Answer[] }>({
    assessment,
    showPercentage = false,
    radius,
    ringRadius,
    ringWidth,
}: {
    assessment: T
    showPercentage?: boolean
    radius?: number
    ringRadius?: number
    ringWidth?: number
}) {
    const progress = useExtract(assessment, ({ answers }) =>
        answers.length ? getReviewedCount(answers) / answers.length : 0
    )
    const formattedPercentage = useMemo(() => {
        const percentage = progress * 100
        return percentage.toFixed(percentage % 1 === 0 ? 0 : 2)
    }, [progress])
    return (
        <div className={cx('d-flex', 'g-5', 'align-items-center')}>
            <CircularProgressIndicator
                progress={progress}
                color={getColorForProgress(progress)}
                radius={radius}
                ringRadius={ringRadius}
                ringWidth={ringWidth}
            />
            {showPercentage && <span>{formattedPercentage}%</span>}
        </div>
    )
}
