import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Icon } from '../components/Icon'
import { themeVariables } from '../themes/themeVariables'

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const StyledIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
`

const Username = styled.span`
    font-size: 14px;
`

interface UsernameAndIconProps {
    username: string
    children?: ReactNode
    className?: string
}

export const UsernameAndIcon = ({
    username,
    children,
    className,
}: UsernameAndIconProps) => {
    return (
        <Container className={className}>
            <StyledIcon name="userCircle" />
            <Username>{username}</Username>
            {children && children}
        </Container>
    )
}
