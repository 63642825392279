import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useProjectsQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { route } from '../utils/routes'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'

export default function ProjectListPage() {
    const [search, setSearch] = useState<string>('')
    const projectsQuery = useProjectsQuery({
        search,
    })
    const projects = projectsQuery.data || []

    const navigate = useNavigate()

    return (
        <Layout>
            <ListTopBar onSearch={(query) => setSearch(query)} />
            <HorizontalLoadingIndicator loading={projectsQuery.isLoading} />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>Projects</h1>
                <Button
                    $size="medium"
                    onClick={() => navigate(route('projectCreate'))}
                >
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">Create project</span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        render: (project) => (
                            <span className="emphasized">{project.name}</span>
                        ),
                        width: 'minmax(min-content, 80%)',
                    },
                    {
                        title: 'Country',
                        key: 'country',
                    },
                    {
                        title: 'Last update',
                        key: 'updatedAt',
                        render: (project) => (
                            <DateRenderer value={project.updatedAt} />
                        ),
                    },
                ]}
                data={projects}
                onRowClick={(project) => {
                    navigate(route('project', project.id))
                }}
                stickyHeader
            />
            {projectsQuery.isLoading && <TableDataLoading />}
            {!projectsQuery.isLoading && projects.length === 0 && (
                <TableNoContent
                    icon="grid"
                    title="Create first Project"
                    description="Projects contain metadata and documents about a project being assessed."
                    buttonText="Create Project"
                    buttonOnClick={() => navigate(route('projectCreate'))}
                />
            )}
        </Layout>
    )
}
