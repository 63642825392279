import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import {
    TableNoContentContainer,
    TableNoContentIcon,
    TableNoContentIconContainer,
} from './Table'
import { Icon, IconName } from './Icon'
import { Button } from './Button'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30vh 7px;
    max-width: 370px;
    margin: 0 auto;
`

export const TableNoContent = ({
    icon,
    title,
    description,
    buttonText,
    buttonOnClick,
}: {
    icon: IconName
    title: string
    description: string
    buttonText: string
    buttonOnClick: () => void
}) => {
    return (
        <Container>
            <TableNoContentContainer>
                <TableNoContentIconContainer>
                    <TableNoContentIcon name={icon} />
                </TableNoContentIconContainer>
                <div className={cx('large', 'emphasized')}>{title}</div>
                <div className="secondary">{description}</div>
                <Button
                    onClick={buttonOnClick}
                    className={cx(
                        'd-flex',
                        'g-5',
                        'm-t-25',
                        'align-items-center'
                    )}
                >
                    <Icon name="plus" />
                    {buttonText}
                </Button>
            </TableNoContentContainer>
        </Container>
    )
}
