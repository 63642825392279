import { object, string } from 'yup'

export const ProjectValidationSchema = object().shape({
    customId: string().required('Field is required'),
    name: string().required('Field is required'),
    description: string().required('Field is required'),
    country: string(),
    sector: string(),
    assignedToId: string().required('Field is required'),
})
